

import {
  Elements,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './StripeForm';

const Stripe = (props: any) => {
  //pk_test_51OOn20JdUDNMvRmhjtoiWTr9ChNSdIRXFhhQ0dJRC9rEiZODQ0TaTe4OGJ9DGjSojWzNFwBiAxfI3vFSxp8MPm0D00mS5MMqPD 
  //pk_live_51OOn20JdUDNMvRmhdOxZPf9OqCxjfmDvtguQpYiV0w0rCqxnabyQNHwc33vxKqk0WzPFEJSUNiqGDgARkXtmDn8P00XE63VObY
  const stripePromise = loadStripe("pk_test_51OOn20JdUDNMvRmhjtoiWTr9ChNSdIRXFhhQ0dJRC9rEiZODQ0TaTe4OGJ9DGjSojWzNFwBiAxfI3vFSxp8MPm0D00mS5MMqPD");
  const options = {
    clientSecret: props.paymentIntent,
  };
  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <StripeForm onSuccess={(data: any) => {
          props.onSuccess(data);
        }} onFailed={(data: any) => {
          props.onFailed(data);
        }}></StripeForm>
      </Elements >
    </>);
};

export default Stripe;

