import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import AIDatePicker from "../Common/AIDatePicker/AIDatePicker";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import FbLogo from "../../assets/images/fb-logo.svg";
import Xlogo from "../../assets/images/socialChannels/twitter_icon.svg";
import InstgramLogo from "../../assets/images/insta-logo.svg";
import linkdnLogo from "../../assets/images/linkedin-logo.svg";
import youtubeLogo from "../../assets/images/socialChannels/youtube-logo.svg";
import stocktwitsLogo from "../../assets/images/socialChannels/stacktwits-icon.png";
import NoImage from "../../assets/images/noImage.png";
import WMDateRangePicker from "../Common/WMDateRangePicker/WMDateRangePicker";

interface propsData {
  reviewId: any;
  type: any;
  websiteId?: any
}
const ReviewAllFilterBlade = (props: propsData) => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [connectors, setConnectors] = useState<any>([]);
  const [allConnectors, setAllConnectors] = useState<any>([]);
  const [connectorError, setConnectorsError] = useState<any>("");
  const [date_range, setdateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    control,
    unregister,
  } = useForm();

  var watchVariable = watch();

  useEffect(() => {
    watchVariable.websiteId = props.websiteId;
    setValue("websiteId", props.websiteId);
    watchVariable.startDate = startDate;
    setValue("startDate", startDate);
    watchVariable.endDate = endDate;
    setValue("endDate", endDate);
    watchVariable.percentage = 10;
    setValue("percentage", 10);
  }, []);

  const handleReviewAll = () => {
    if (watchVariable.percentage !== "") {
      clearErrors("percentage");
    } else {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }
    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    const newArray = connectors?.map((item: any) => {
      let social_type = item;
      let account_type = "";

      if (item.value.includes('PERSONAL')) {
        social_type = item.value.replace('_PERSONAL', '');
        account_type = 'PERSONAL';
      } else if (item.value.includes('BUSINESS')) {
        social_type = item.value.replace('_BUSINESS', '');
        account_type = 'BUSINESS';
      } else {
        social_type = item.value;
        account_type = '';
      }
      return {
        socialType: social_type,
        account_type: account_type
      };
    });
    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
      return false;
    }

    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
      return;
    } else {
      clearErrors("endDate");
    }
    WebService.addLoader("btn-save");
    return WebService.postAPI({
      action: `review`,
      body: { ...watchVariable, "accounts": newArray, "request_type": "", "accountType": "" },
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        WebService.removeLoader("btn-save");
        props.reviewId(res.uuid);
      })
      .catch((error: any) => {
        WebService.removeLoader("btn-save");
        return error;
      });
  };
  const handleError = (errors: any) => {
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.name === "" || watchVariable.name === undefined) {
      setError("name", {
        type: "custom",
        message: "Please enter name",
      });
    }
    if (
      watchVariable.percentage === "" ||
      watchVariable.percentage === undefined
    ) {
      setError("percentage", {
        type: "custom",
        message: "Please enter percentage",
      });
    }
    if (watchVariable.startDate !== "") {
      clearErrors("startDate");
    } else {
      setError("startDate", {
        type: "custom",
        message: "Please select start date",
      });
    }
    if (watchVariable.endDate !== "") {
      clearErrors("endDate");
    } else {
      setError("endDate", {
        type: "custom",
        message: "Please select end date",
      });
    }

    if (connectors?.length === 0) {
      setConnectorsError("Please select channels");
    }

    const enteredStartDate = new Date(watchVariable.startDate);
    const enteredEndDate = new Date(watchVariable.endDate);
    if (enteredStartDate > enteredEndDate) {
      setError("endDate", {
        type: "custom",
        message: "End date must be greater than start date",
      });
    } else {
      clearErrors("endDate");
    }
  };
  const handleChange = (type: any, value: any) => {
    if (type === "name") {
      watchVariable.name = value;
      setValue("name", value);
      if (watchVariable.name !== "") {
        clearErrors("name");
      } else {
        setError("name", {
          type: "custom",
          message: "Please enter name",
        });
      }
    } else if (type === "percentage") {
      watchVariable.percentage = value;
      setValue("percentage", value);
      if (watchVariable.percentage !== "") {
        clearErrors("percentage");
      } else {
        setError("percentage", {
          type: "custom",
          message: "Please enter percentage",
        });
      }
    } else if (type === "startDate") {
      watchVariable.startDate = value;
      setValue("startDate", value);
      if (watchVariable.startDate !== "") {
        clearErrors("startDate");
      } else {
        setError("startDate", {
          type: "custom",
          message: "rt",
        });
      }
    } else if (type === "endDate") {

      watchVariable.endDate = value;
      setValue("endDate", value);
      if (watchVariable.endDate !== "") {
        clearErrors("endDate");
      } else {
        setError("endDate", {
          type: "custom",
          message: "er",
        });
      }
    }
  };
  const onDateChange = (date: any, type: any) => {
    if (type === "startDate") {
      setStartDate(date);
      watchVariable.startDate = date;
      setValue("startDate", date);
    } else if (type === "endDate") {
      setEndDate(date);
      watchVariable.endDate = date;
      setValue("endDate", date);
    }
  };


  const connectorPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      WebService.postAPI({
        action: `user-integration/all-connectors`,
        // user-integration/list  
        body: {},
        isShowError: true,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            const modifiedIntegrationDetails: any[] = [];
            res.map((channel: any) => {
              if (
                channel.type === "INSTAGRAM" ||
                channel.type === "LINKEDIN" ||
                channel.type === "X"
              ) {
                let hasBusinessAccount = false;
                let hasPersonalAccount = false;

                if (Array.isArray(channel?.list)) {
                  channel?.list?.forEach((item: any) => {
                    if (item.accountType === "BUSINESS") {
                      hasBusinessAccount = true;
                    }
                    if (item.accountType === "PERSONAL") {
                      hasPersonalAccount = true;
                    }
                  });
                }

                if (hasBusinessAccount) {
                  const businessIntegration = { value: channel.type + "_" + "BUSINESS", label: channel.type + " " + "BUSINESS" };
                  modifiedIntegrationDetails.push(businessIntegration);
                }

                if (hasPersonalAccount) {
                  const personalIntegration = { value: channel.type + "_" + "PERSONAL", label: channel.type + " " + "PERSONAL" };
                  modifiedIntegrationDetails.push(personalIntegration);
                }
              } else {
                modifiedIntegrationDetails.push({ value: channel.type, label: channel.type === "SMS" ? "ANDROID SMS" : channel.type.replaceAll("_", " ") });
              }
            });

            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...modifiedIntegrationDetails];
            setAllConnectors(options);
            resolve(options);
          } else {
            setAllConnectors([]);
            resolve([]);
          }
        })
        .catch((error: any) => {
          setAllConnectors([]);
          resolve([]);
          return error;
        });
    });

  const onConnectorSelection = (selectedOptions: any) => {
    setConnectorsError("");
    const isSelectAllSelected = selectedOptions.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];


    if (isSelectAllSelected) {
      arr = allConnectors
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOptions;
    }

    setConnectors(arr);
  };





  //Email
  const [emailSenders, setEmailSenders] = useState<any>("");
  const [emailReceivers, setEmailReceivers] = useState<any>("");

  const handleEmailSendersChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setEmailSenders(values);
  };

  const handleEmailReceiversChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setEmailReceivers(values);
  };

  //SMS  / MyRepChat / iMessage / Zoom SMS
  const [senders, setSenders] = useState<any>("");
  const [receivers, setReceivers] = useState<any>("");

  const handleSendersChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setSenders(values);
  };

  const handleReceiversChange = (e: any) => {
    const values = e.target.value.split(",").map((value: any) => value.trim());
    setReceivers(values);
  };

  //Teams
  const [selectedTeamsParticipant, setSelectedTeamsParticipant] = useState<any>([]);
  const [allTeamsAccounts, setAllTeamsAccounts] = useState<any>([]);

  const teamsAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=TEAMS&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            var pagesOptions = res?.map((page: any, index: any) => ({
              value: page.id,
              label: page.name,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllTeamsAccounts(options);
            resolve(options);
          } else {
            setAllTeamsAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onTeamsAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allTeamsAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedTeamsParticipant(arr);
  };

  //Zoom Chat
  const [selectedZoomParticipant, setSelectedZoomParticipant] = useState<any>([]);
  const [allZoomAccounts, setAllZoomAccounts] = useState<any>([]);

  const zoomAccountPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=ZOOM_CHAT&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res?.length > 0) {
            var pagesOptions = res?.map((page: any, index: any) => ({
              value: page.id,
              label: page.name,
            }));
            const options = [{ value: "SELECT_ALL", label: "Select All" }, ...pagesOptions];
            setAllZoomAccounts(options);
            resolve(options);
          } else {
            setAllZoomAccounts([]);
            resolve([]);
            return errors;
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onZoomAccountSelection = (selectedOption: any) => {
    const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
    let arr: any = [];
    if (isSelectAllSelected) {
      arr = allZoomAccounts
        .filter((option: any) => option.value !== "SELECT_ALL")
        .map((option: any) => option);
    } else {
      arr = selectedOption;
    }

    setSelectedZoomParticipant(arr);
  };

  //Social Media Apps 
  const [selectedFacebookAccount, setSelectedFacebookAccount] = useState<any[]>([]);
  const [allFacebookAccounts, setAllFacebookAccounts] = useState<any>([]);
  const [selectedInstagramPAccount, setSelectedInstagramPAccount] = useState<any[]>([]);
  const [allInstagramPAccounts, setAllInstagramPAccounts] = useState<any>([]);
  const [selectedLinkedInPAccount, setSelectedLinkedInPAccount] = useState<any[]>([]);
  const [allLinkedInPAccounts, setAllLinkedInPAccounts] = useState<any>([]);
  const [selectedLinkedInBAccount, setSelectedLinkedInBAccount] = useState<any[]>([]);
  const [allLinkedInBAccounts, setAllLinkedInBAccounts] = useState<any>([]);
  const [selectedTwitterPAccount, setSelectedTwitterPAccount] = useState<any[]>([]);
  const [allTwitterPAccounts, setAllTwitterPAccounts] = useState<any>([]);
  const [selectedTwitterBAccount, setSelectedTwitterBAccount] = useState<any[]>([]);
  const [allTwitterBAccounts, setAllTwitterBAccounts] = useState<any>([]);
  const [selectedYoutubeAccount, setSelectedYoutubeAccount] = useState<any[]>([]);
  const [allYoutubeAccounts, setAllYoutubeAccounts] = useState<any>([]);
  const [selectedStocktwitsAccount, setSelectedStocktwitsAccount] = useState<any[]>([]);
  const [allStocktwitsAccounts, setAllStocktwitsAccounts] = useState<any>([]);

  const onSocialAccountSelection = (selectedOption: any, type: string) => {
    if (type === "FACEBOOK") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allFacebookAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedFacebookAccount(arr);
    }

    if (type === "X_BUSINESS") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterBAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }
      setSelectedTwitterBAccount(arr);
    }

    if (type === "X_PERSONAL") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allTwitterPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedTwitterPAccount(arr);
    }

    if (type === "LINKEDIN_BUSINESS") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allLinkedInBAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedLinkedInBAccount(arr);
    }

    if (type === "LINKEDIN_PERSONAL") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allLinkedInPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedLinkedInPAccount(arr);
    }

    if (type === "INSTAGRAM_PERSONAL") {

      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allInstagramPAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedInstagramPAccount(arr);
    }

    if (type === "YOUTUBE") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allYoutubeAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedYoutubeAccount(arr);

    }

    if (type === "STOCKTWITS") {
      const isSelectAllSelected = selectedOption.some((option: any) => option.value === "SELECT_ALL");
      let arr: any = [];
      if (isSelectAllSelected) {
        arr = allStocktwitsAccounts
          .filter((option: any) => option.value !== "SELECT_ALL")
          .map((option: any) => option);
      } else {
        arr = selectedOption;
      }

      setSelectedStocktwitsAccount(arr);
    }

  };

  const socialAccountPromiseOptions = (inputValue: any, social_type: string, account_type: string) =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `filter/socialpost?social_type=${social_type}&account_type=${account_type}&keyword=` + inputValue,
        body: null,
        access_token: access_token,
        t_id: t_id
      })
        .then((res: any) => {
          if (res.length > 0) {
            var instagramOption: any[] = [];
            var twitterOption: any[] = [];
            var facebookOption: any[] = [];
            var linkdnOption: any[] = [];
            var youtubeOption: any[] = [];
            var stocktwitsOption: any[] = [];

            res.forEach((channel: any) => {
              var obj: any = {
                value: channel.id,
                label: (
                  <>
                    <span className="ms-3 font-14">{channel.name}</span>
                  </>
                ),
                social_type: channel.socialType,
                accountType: channel.accountType
              };

              if (channel.socialType === "INSTAGRAM") {
                instagramOption.push(obj);
                setAllInstagramPAccounts(instagramOption);
              } else if (channel.socialType === "X") {
                twitterOption.push(obj);
                if (account_type === "PERSONAL") {
                  setAllTwitterPAccounts(twitterOption);
                } else {
                  setAllTwitterBAccounts(twitterOption);
                }
              } else if (channel.socialType === "FACEBOOK") {
                facebookOption.push(obj);
                setAllFacebookAccounts(facebookOption);
              } else if (channel.socialType === "LINKEDIN") {
                linkdnOption.push(obj);
                if (account_type === "PERSONAL") {
                  setAllLinkedInPAccounts(linkdnOption);
                } else {
                  setAllLinkedInBAccounts(linkdnOption);
                }
              } else if (channel.socialType === "YOUTUBE") {
                youtubeOption.push(obj);
                setAllYoutubeAccounts(youtubeOption);
              } else if (channel.socialType === "STOCKTWITS") {
                stocktwitsOption.push(obj);
                setAllStocktwitsAccounts(stocktwitsOption);
              }

            });

            var groupedOptions: any = [];
            if (instagramOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={InstgramLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Instagram"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...instagramOption],
              });
            }
            if (twitterOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={Xlogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    X
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...twitterOption],
              });
            }
            if (facebookOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={FbLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Facebook"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...facebookOption],
              });
            }
            if (linkdnOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={linkdnLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Linkedin"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...linkdnOption],
              });
            }
            if (youtubeOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={youtubeLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"YouTube"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...youtubeOption],
              });
            }
            if (stocktwitsOption.length > 0) {
              groupedOptions.push({
                label: (
                  <>
                    <img
                      src={stocktwitsLogo}
                      alt="logo"
                      width={15}
                      className="me-1"
                      onError={(error: any) => {
                        error.target.src = NoImage;
                      }}
                    />
                    {"Stocktwits"}
                  </>
                ),
                options: [{ value: "SELECT_ALL", label: (<span className="ms-3 font-14">Select All</span>), social_type: account_type }, ...stocktwitsOption],
              });
            }

            resolve(groupedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const dateChange = (date_range: any) => {
    setValue("startDate", date_range.startDate);
    setValue("endDate", date_range.endDate);
    setdateRange(date_range);
  };

  return (
    <>
      <Form
        className="w-100 form-style"
        name="Verify"
        id="Verify"
        onSubmit={handleSubmit(handleReviewAll, handleError)}
      >
        <div className="pt-3">
          <Row>
            <Col>
              <FloatingLabel
                controlId="ReviewPercentage"
                label="Review list name"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  maxLength={255}
                  {...register("name", {
                    required: true,
                  })}
                  onChange={(e: any) => {
                    handleChange("name", e.target.value);
                  }}
                />
                {errors.name && (
                  <Label
                    title={
                      errors.name.message ? errors.name.message.toString() : ""
                    }
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>
        </div>
        <div className="mb-3">
          <Row>
            <Col>
              <InputGroup className=" perce-adon ">
                <Form.Control
                  value={"Reviews percentage"}
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  className="font-12 font-medium"
                />
                <InputGroup.Text id="basic-addon2">
                  <span className="">{watchVariable.percentage}%</span>{" "}
                </InputGroup.Text>
              </InputGroup>
            </Col>
            <Col>
              <FloatingLabel
                controlId="ReviewPercentage"
                label="Review Percentage"
                className=""
              >
                <Form.Control
                  type="text"
                  {...register("percentage", {
                    required: true,
                    pattern: {
                      value: /^[1-9]\d?$|^100$/,
                      message: "Please enter a valid number between 1 and 100",
                    },
                  })}
                  value={watch("percentage") || ""}
                  onChange={(e: any) => {
                    const enteredValue = e.target.value;
                    if (parseInt(enteredValue) > 100) {
                      setError("percentage", {
                        type: "custom",
                        message:
                          "Please enter a valid number between 1 and 100",
                      });
                    } else {
                      handleChange("percentage", enteredValue);
                    }
                  }}
                />
                {errors.percentage && (
                  <Label
                    title={
                      errors.percentage.message
                        ? errors.percentage.message.toString()
                        : ""
                    }
                    modeError={true}
                    showStar={true}
                    type=""
                  />
                )}
              </FloatingLabel>
            </Col>
          </Row>
        </div>
        <div className="">
          <div className="">
            <div>
              <WMDateRangePicker
                selectData={(date_range: any) => {
                  dateChange(date_range);
                }}
                date_range={date_range}
              >
              </WMDateRangePicker>
              {/* <Row>
                <Col>
                  <AIDatePicker
                    date={startDate}
                    selectDate={(date: any) => onDateChange(date, "startDate")}
                    maxDate={new Date()}
                  />
                </Col>
                <Col>
                  <AIDatePicker
                    {...register("endDate")}
                    date={endDate}
                    selectDate={(date: any) => onDateChange(date, "endDate")}
                    maxDate={new Date()}
                  />
                </Col>
              </Row> */}

            </div>
            <div style={{ marginLeft: "7px" }}>

            </div>
          </div>
          <div className="mb-1">
            {errors.endDate && (
              <Label
                title={
                  errors.endDate.message?.toString() ?? "Please select end date"
                }
                modeError={true}
                showStar={true}
                type=""
              />
            )}
          </div>

          <div className="mb-3">
            <AsyncSelect
              cacheOptions
              defaultOptions
              onChange={onConnectorSelection}
              isMulti
              loadOptions={connectorPromiseOptions}
              placeholder="Select channels"
              value={connectors}
            />
            <span className="text-danger font-12">{connectorError}</span>
          </div>

          {(connectors?.some((item: any) => item?.value === "TEAMS")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>Teams</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onTeamsAccountSelection(value) }}
                    isMulti
                    loadOptions={teamsAccountPromiseOptions}
                    placeholder="Select Account"
                    // defaultValue={selectedTeamsParticipant?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    // }))}
                    value={selectedTeamsParticipant}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "EMAIL")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className="mb-3">
                <h6>Email</h6>
                <div className="mt-1 mb-3">
                  <Controller
                    name="emailSender"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="senders" label="Senders">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleEmailSendersChange(value);
                          }}
                          placeholder="Senders"
                          value={emailSenders}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.emailSender && (
                    <Label
                      title={errors.emailSender.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>
                <div className="mt-3">
                  <Controller
                    name="emailReceiver"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Please enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="receiver" label="Receivers">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleEmailReceiversChange(value);
                          }}
                          placeholder="Receivers"
                          value={emailReceivers}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.emailReceiver && (
                    <Label
                      title={errors.emailReceiver.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>
              </div>
            </>
          }

          {((connectors?.some((item: any) => item?.value === "SMS")) ||
            (connectors?.some((item: any) => item?.value === "IMESSAGE")) ||
            (connectors?.some((item: any) => item?.value === "MY_REPCHAT")) ||
            (connectors?.some((item: any) => item?.value === "ZOOM_SMS"))
          ) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>SMS / MyRepChat / iMessage / Zoom SMS / Other SMS</h6>
                <div className="mt-1 mb-3">
                  <Controller
                    name="smsSender"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,3}\)?[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4})?$/,
                        message: "Please enter a valid number"
                      }
                    }}
                    render={({ field }) => (
                      <FloatingLabel controlId="senders" label="Senders">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleSendersChange(value);
                          }}
                          placeholder="Senders"
                          value={senders}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.smsSender && (
                    <Label
                      title={errors.smsSender.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>
                <div className="mt-3">
                  <Controller
                    name="smsReceiver"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\+?(\d{1,4}[-.\s]?)?(\(?\d{1,3}\)?[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4}[-.\s]?)?(\d{1,4})?$/,
                        message: "Please enter a valid number"
                      }
                    }}
                    render={({ field }) => (
                      <FloatingLabel
                        controlId="receiver"
                        label="Receivers">
                        <Form.Control
                          type="text"
                          onChange={(value: any) => {
                            field.onChange(value);
                            handleReceiversChange(value);
                          }}
                          placeholder="Receivers"
                          value={receivers}
                        />
                      </FloatingLabel>
                    )}
                  />
                  {errors.smsReceiver && (
                    <Label
                      title={errors.smsReceiver.message?.toString()}
                      modeError={true}
                      showStar={true}
                      type=""
                    />
                  )}
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "ZOOM_CHAT")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>Zoom Chat</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onZoomAccountSelection(value) }}
                    isMulti
                    loadOptions={(inputValue: string) => zoomAccountPromiseOptions(inputValue)}
                    placeholder="Select Account"
                    // defaultValue={selectedZoomParticipant?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    // }))}
                    value={selectedZoomParticipant}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "FACEBOOK")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>Facebook</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "FACEBOOK") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "FACEBOOK", "BUSINESS")}
                    placeholder="Select Account"
                    // defaultValue={selectedFacebookAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedFacebookAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "X_BUSINESS")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>X Business</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "X_BUSINESS") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "X", "BUSINESS")}
                    placeholder="Select Account"
                    // defaultValue={selectedTwitterBAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedTwitterBAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "X_PERSONAL")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>X Personal</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "X_PERSONAL") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "X", "PERSONAL")}
                    placeholder="Select Account"
                    // defaultValue={selectedTwitterPAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedTwitterPAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "LINKEDIN_BUSINESS")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>LinkedIn Business</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "LINKEDIN_BUSINESS") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "LINKEDIN", "BUSINESS")}
                    placeholder="Select Account"
                    // defaultValue={selectedLinkedInBAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedLinkedInBAccount}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "LINKEDIN_PERSONAL")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>LinkedIn Personal</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "LINKEDIN_PERSONAL") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "LINKEDIN", "PERSONAL")}
                    placeholder="Select Account"
                    // defaultValue={selectedLinkedInPAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedLinkedInPAccount}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "INSTAGRAM_PERSONAL")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>Instagram Personal</h6>
                <div className="mt-1">

                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "INSTAGRAM_PERSONAL") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "INSTAGRAM", "PERSONAL")}
                    placeholder="Select Account"
                    // defaultValue={selectedInstagramPAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedInstagramPAccount}
                  />

                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "YOUTUBE")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>YouTube</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "YOUTUBE") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "YOUTUBE", "PERSONAL")}
                    placeholder="Select Account"
                    // defaultValue={selectedYoutubeAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedYoutubeAccount}
                  />
                </div>
              </div>
            </>
          }

          {(connectors?.some((item: any) => item?.value === "STOCKTWITS")) &&
            <>
              <hr className="border-secondary-subtle w-100" />
              <div className=" mb-3">
                <h6>Stocktwits</h6>
                <div className="mt-1">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    onChange={(value: any) => { onSocialAccountSelection(value, "STOCKTWITS") }}
                    isMulti
                    loadOptions={(inputValue: string) => socialAccountPromiseOptions(inputValue, "STOCKTWITS", "BUSINESS")}
                    placeholder="Select Account"
                    // defaultValue={selectedStocktwitsAccount?.map((item: any, index: any) => ({
                    //   label: item.label,
                    //   value: item.id,
                    //   social_type: item.social_type,
                    // }))}
                    value={selectedStocktwitsAccount}
                  />

                </div>
              </div>
            </>
          }

          <Button type="submit" className="btn btn-brand-1 w-100" id="btn-save">
            Start Review
          </Button>
          <span className="mt-2 text-danger font-12">Note: The "Review All" feature will not include messages that are already flagged or escalated.</span>
        </div>
      </Form>
    </>
  );
};

export default ReviewAllFilterBlade;

























































































