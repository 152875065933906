import { persistor } from "./store";

export const updateProfileImage = (imageUrl) => ({
  type: 'UPDATE_PROFILE_IMAGE',
  payload: imageUrl,
});


export const updateProgressBar = (data) => ({
  type: 'UPDATE_PROGRESS_BAR',
  payload: data,
});


export const userRole = (role) => ({
  type: 'USER_ROLE',
  payload: role,
});

export const userId = (id) => ({
  type: 'USER_ID',
  payload: id,
});

export const userName = (name) => ({
  type: 'USER_NAME',
  payload: name,
});

export const twoFAEnabled = (isEnabled) => ({
  type: 'TWO_FA_ENABLED',
  payload: isEnabled,
});

export const userEmail = (email) => ({
  type: 'USER_EMAIL',
  payload: email,
});

export const clientId = (clientId) => ({
  type: 'CLIENT_ID',
  payload: clientId,
});

export const tenantId = (tenantId) => ({
  type: 'TENANT_ID',
  payload: tenantId,
});

export const uat = (uat) => ({
  type: 'UAT',
  payload: uat,
});

export const iA = (iA) => ({
  type: 'INBOUND_ARCHIVING',
  payload: iA,
});

export const oA = (oA) => ({
  type: 'OUTBOUND_ARCHIVIN',
  payload: oA,
});

export const aT = (aT) => ({
  type: 'ACCESS_TOKEN',
  payload: aT,
});

export const aiM = (aiM) => ({
  type: 'AI_MODEL',
  payload: aiM,
});

export const aCE = (aCE) => ({
  type: 'AI_CHATBOT_ENABLED',
  payload: aCE,
});

export const vCE = (vCE) => ({
  type: 'VAULT_ENABLED',
  payload: vCE,
});

export const sUrl = (sUrl) => ({
  type: 'SALESFORCE_URL',
  payload: sUrl,
});

export const oS = (oS) => ({
  type: 'OTHER_SERVICE',
  payload: oS,
});

export const logout = () => (dispatch, getState) => {  
  dispatch({ type: 'LOGOUT' }); 
  persistor.purge();
};




 