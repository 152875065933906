import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Offcanvas,
  FloatingLabel,
  Dropdown,
} from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { IoSearchOutline } from "react-icons/io5";
import { BsFunnel } from "react-icons/bs";
import FilterBlade from "./FilterBlade";
import MessageTab from "./MessageTab";
import ReviewAllFilterBlade from "../../ReviewAll/ReviewAllFilterBlade";
import "./message-review.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import WebService from "../../../Services/WebService";
import NoDataFound from "../../../assets/images/empty_item.svg";
import { useSelector } from "react-redux";
const MessageReview = () => {
  const t_id = useSelector((state: any) => state.tI.tenantId);
  const access_token = useSelector((state: any) => state.aT.aT);
  const role = useSelector((state: any) => state.uR.role);
  // Start Review Blade
  const [showReviewBlade, setShowReviewBlade] = useState(false);
  const handleCloseReviewBlade = () => setShowReviewBlade(false);
  const handleShowReviewBlade = () => setShowReviewBlade(true);

  const [showFilterBlade, setShowFilterBlade] = useState(false);
  const handleCloseFilterBlade = () => setShowFilterBlade(false);
  const handleShowFilterBlade = () => setShowFilterBlade(true);

  const [showBulkReviewBlade, setShowBulkReviewBlade] = useState(false);
  const handleCloseBulkReviewBlade = () => setShowBulkReviewBlade(false);

  const [changedObject, setChangedObject] = useState(false);
  const [filterObject, setFilterObject] = useState<any>({});
  const [keyword, setKeyword] = useState("");
  const [viewObject, setViewObject] = useState<any>(null);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [reviewTab, setReviewTab] = useState("");
  const [automark, setAutoMark] = useState(role !== "BASIC_USER" ? true : false);
  const [bulkReviewList, setBulkReviewList] = useState<any[]>([]);
  const [changeInList, setChangeInList] = useState<any>([]);
  const [reviewFlag, setReviewFlag] = useState(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [complainceUserLogAccess, setComplainceUserLogAccess] = useState(true);
  const [selectedTab, setSelectedTab] = useState<any>("");
  const [activeChannelsList, setActiveChannelsList] = useState<any>([]);
  const [filterFlag, setFilterFlag] = useState<any>(false);
  const [keywordFlag, setKeywordFlag] = useState<any>(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  let watchVariable = watch();

  useEffect(() => {
    if (filterFlag) {
      setFilterFlag(false);
    }
  }, [filterObject]);

  useEffect(() => {
    if (keywordFlag) {
      setKeyword("");
    }
    setKeywordFlag(false);
  }, [keywordFlag]);


  useEffect(() => {
    var data1 = atob(localStorage.getItem('ucd') ?? "");
    if (role === "COMPLIANCE_OFFICER") {
      if ((data1 === "" || data1 === undefined)) {
        navigate("/access-denied");
      } else {
        var json = JSON.parse(data1);
        setAutoMark(json.reviewLogs);
        setComplainceUserLogAccess(json.reviewLogs)
      }
    }
    getActiveChannel();
  }, []);

  useEffect(() => {

  }, [selectedTab]);


  const toggleViewSidebar = () => {
    setIsActive(true);
  };

  const changeIsActive = (isAct: any, tab?: any) => {
    if (tab) {
      setReviewTab(tab);
    }
    setIsActive(isAct);
  };
  const handleShowBulkReviewBlade = () => {
    if (bulkReviewList.length === 0) {
      toast.error("Please select message for bulk review");
    } else {
      setShowBulkReviewBlade(true);
    }
  };
  const handleAddNotes = () => {
    if (
      // watchVariable.markedAsFlag === undefined &&
      watchVariable.markedAsView === undefined
      //  &&
      // watchVariable.escalateAndSend === undefined &&
      // watchVariable.notes === undefined
    ) {
      // watchVariable.markedAsFlag = false;
      setValue("markedAsView", false);
      watchVariable.markedAsView = false;
      // watchVariable.escalateAndSend = false;
      // watchVariable.notes = "";
    }
    let body = {
      idList: bulkReviewList,
      markedAsFlag: !watchVariable.markedAsView ? false : watchVariable.markedAsFlag, //uncommented
      markedAsView: watchVariable.markedAsView,
      escalateAndSend: !watchVariable.markedAsView ? false : watchVariable.escalateAndSend,  //uncommented
      notes: watchVariable.notes,
      socialType: "WHATSAPP",
    };
    WebService.addLoader("save");
    return WebService.putAPI({
      action: `social/bulk-review`,
      body: body,
      isShowError: true,
      access_token: access_token,
      t_id: t_id
    })
      .then((res: any) => {
        toast.success(res?.message);
        WebService.removeLoader("save");
        handleCloseBulkReviewBlade();
        setChangeInList(body);
        reset()
        setChangedObject(false);
        setBulkReviewList([])
      })
      .catch((error: any) => {
        WebService.removeLoader("save");
        handleCloseBulkReviewBlade();
        return error;
      });
  };


  const getActiveChannel = () => {
    return WebService.getAPI({
      action: `active-text`,
      body: null,
      access_token: access_token,
      t_id: t_id

    })
      .then((res: any) => {
        if (res.length > 0) {
          var smsChannels: any[] = []
          const filteredResponse = filterSocialTypes(res);
          filteredResponse.forEach((item: any) => {
            if (item.social_type === "WHATSAPP") {
              smsChannels.push(item.social_type);
            }
          });
          setActiveChannelsList(smsChannels);
          setSelectedTab(smsChannels[0]);
        }
      })
      .catch((error: any) => {
        return error;
      });
  };


  const gotoConnectPage = () => {
    navigate("/connector");
  }


  function filterSocialTypes(data: any) {
    const uniqueSocialTypes: any[] = [];
    const seenSocialTypes = new Set();
    data.forEach((item: any) => {
      if (!seenSocialTypes.has(item.social_type)) {
        seenSocialTypes.add(item.social_type);
        uniqueSocialTypes.push(item);
      }
    });

    return uniqueSocialTypes;
  }

  return (
    <>
      <div className="page-contetn page-flagging page-whatsapp-review">
        <Row className="mb-3 align-items-center filter-row">
          <Col lg={3} xxl={5}>
            <PageTitle Title="WhatsApp Review" />
          </Col>
          <Col lg={9} xxl={7} className="d-flex justify-content-end gap-2">
            <InputGroup className="table-search-box">
              <InputGroup.Text id="basic-addon1">
                <IoSearchOutline className="icon" />
              </InputGroup.Text>
              <Form.Control
                key={keyword}
                placeholder="Search"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    setKeyword(e.currentTarget.value);
                    filterObject.keywords = e.currentTarget.value;
                  }
                  if (e.currentTarget.value === "" && e.key === "Enter") {
                    setKeywordFlag(true);
                  }
                }}
                defaultValue={keyword}
              />
            </InputGroup>
            {complainceUserLogAccess && role !== "BASIC_USER" ? <div className="bg-white rounded px-3 d-flex text-nowrap gap-2 font-14 align-items-center">
              <span className="mb-1">Auto-mark</span>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="ON"
                className="mb-"
                checked={automark}
                onChange={() => {
                  setAutoMark(!automark);
                }}
              />
            </div> : ""}

            <Button
              style={totalCount === 0 || !complainceUserLogAccess ? { position: "relative", marginRight: "12px" } : {}}
              className="btn-brand-light text-nowrap"
              onClick={handleShowFilterBlade}
            >
              {" "}
              <BsFunnel size={18} /> Filter{" "}
            </Button>
            {totalCount > 0 && complainceUserLogAccess && role !== "BASIC_USER" ? (

              <>
                <Button
                  className="btn-brand-1 text-nowrap"
                  onClick={() => {
                    setValue("markedAsView", true);
                    setValue("markedAsViewHead", "Mark as viewed");
                    handleShowBulkReviewBlade();
                  }
                  }
                >
                  Bulk Review
                </Button>
                {/* <Button
                  style={{ position: "relative" }}
                  className="btn-brand-1 text-nowrap"
                  onClick={handleShowReviewBlade}
                >
                  Start Review <BsArrowRight size={16} />
                </Button> */}
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>


        {activeChannelsList.length === 0 ?
          <div className="table-wrap position-relative">
            <div className="no-data-flound">
              <div className="text-center">
                <img src={NoDataFound} width={110} alt="noDataimage" className="mb-3" />
                <h3 className="text-secondary font-12">
                  <p>No number is currently connected for archiving.</p>
                  <Button className="btn-brand-1" onClick={gotoConnectPage} >
                    + Connect now
                  </Button>
                </h3>
              </div>
            </div>
          </div> :
          <div className="collapse-page">
            <div className="data-container">
              <MessageTab
                selectedTab={selectedTab}
                viewObject={(data: any) => {
                  setViewObject(data);
                  toggleViewSidebar();
                }}
                automark={automark}
                changedObject={changedObject}
                keyWord={keyword}
                filterObject={filterObject}
                clearFilterCallback={(type: any, data: any) => {
                  setFilterFlag(true);
                  let obj = { ...filterObject };
                  if (type === "ALL") {
                    obj = {};
                    setKeywordFlag(true);
                    setKeyword("");
                  } else if (type === "Sender") {
                    obj.sender = obj.sender.filter((account: any) => account !== data);
                  } else if (type === "Recipient") {
                    obj.recipient = obj.recipient.filter((account: any) => account !== data);
                  } else if (type === "Date") {
                    obj.startDate = undefined;
                    obj.endDate = undefined;
                  } else if (type === "Percentage") {
                    obj.percentage = undefined;
                  } else if (type === "Keyword") {
                    obj.keywords = "";
                    setKeyword("");
                  } else if (type === "Flagged") {
                    obj.markedAsFlag = undefined;
                  } else if (type === "Reviewed") {
                    obj.markedAsView = undefined;
                  } else if (type === "Escalated") {
                    obj.escalateAndSend = undefined;
                  } else if (type === "NotReviewed") {
                    obj.flaggedNotReviewed = undefined;
                  } else if (type === "NotYetReviewed") {
                    obj.notReviewed = undefined;
                  } else {
                    obj = { ...obj, ...data };
                  }
                  setFilterObject(obj);
                }}
                isActive={isActive}
                reviewTab={reviewTab}
                changeIsActive={changeIsActive}
                sendData={(data: any) => {
                  setChangedObject(data);
                }}
                sendBulkReviewList={(selectedIds: any) => {
                  setBulkReviewList(selectedIds);
                }}
                changeInList={changeInList}
                totalCount={(count: any) => {
                  setTotalCount(count);
                }}
              />
            </div>
          </div>
        }
      </div>

      <Offcanvas
        show={showReviewBlade}
        onHide={handleCloseReviewBlade}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Start Review</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ReviewAllFilterBlade
            type={"WHATSAPP"}
            reviewId={(reviewId: any) => {
              handleCloseReviewBlade();
              navigate("/review/review-all/" + reviewId);
            }}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showFilterBlade}
        onHide={handleCloseFilterBlade}
        placement="end"
      >
        <FilterBlade
          sendData={(filterObject: any) => {
            if (filterObject.keywords) {
              setKeyword(filterObject.keywords);
            } else {
              setKeyword("");
            }
            if (JSON.stringify(filterObject) === "{}") {
              setKeywordFlag(true);
              setKeyword("");
            }
            setFilterObject(filterObject);
            handleCloseFilterBlade();
          }}
          parentCallback={filterObject}
        />
      </Offcanvas>

      <Offcanvas
        show={showBulkReviewBlade}
        onHide={handleCloseBulkReviewBlade}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Bulk Review</Offcanvas.Title>
        </Offcanvas.Header>
        <Form
          className="w-100 form-style h-100"
          name="Verify"
          id="Verify"
          onSubmit={handleSubmit(handleAddNotes)}
        >
          <Offcanvas.Body>
            {/* <div className="px-3 mb-3">
              <div className="d-flex gap-2 mb-1"> */}
            {/* <div className="px-2 py-1 bg-danger-subtle">
                  <Form.Check
                    {...register("markedAsFlag")}
                    type="checkbox"
                    id="Flag"
                    label="Flag"
                    className="labe-text-dark checkbox-danger"
                    checked={watchVariable.markedAsFlag}
                    onChange={() => {
                      if (watchVariable.markedAsFlag) {
                        watchVariable.markedAsFlag = false;
                        setValue("markedAsFlag", false);
                        setReviewFlag(false);
                      } else {
                        watchVariable.markedAsFlag = true;
                        setValue("markedAsFlag", true);
                      }
                    }}
                  />
                </div> */}
            {/* <div className="px-2 py-1 bg-success-subtle">
                  <Form.Check
                    type="checkbox"
                    {...register("markedAsView")}
                    id="MarkViewedWhatsapp"
                    label="Mark as viewed"
                    className="labe-text-dark checkbox-success"
                    checked={watchVariable.markedAsView}
                    onChange={() => {
                      if (watchVariable.markedAsView) {
                        watchVariable.markedAsView = false;
                        setValue("markedAsView", false);
                        setReviewFlag(false);
                      } else {
                        watchVariable.markedAsView = true;
                        setValue("markedAsView", true);
                      }
                    }}
                  />
                </div> */}

            {/* <div className="px-2 py-1 bg-warning-subtle">
                  <Form.Check
                    type="checkbox"
                    {...register("escalateAndSend")}
                    id="EscalateSend"
                    label="Escalate send"
                    className="labe-text-dark checkbox-warning"
                    checked={watchVariable.escalateAndSend}
                    onChange={() => {
                      if (watchVariable.escalateAndSend) {
                        watchVariable.escalateAndSend = false;
                        setValue("escalateAndSend", false);
                        setReviewFlag(false);
                      } else {
                        watchVariable.escalateAndSend = true;
                        setValue("escalateAndSend", true);
                      }
                    }}
                  />
                </div> */}
            {/* </div>
            </div> */}
            <div>
              <Dropdown className="dropdown-toggle2">
                <Dropdown.Toggle
                  id=""
                  variant=""
                  className="bg-success-subtle dropdown-toggle2"
                // block-button
                >
                  <span className="font-16" style={{ color: "Black" }}>{watchVariable.markedAsViewHead}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => { setValue("markedAsView", true); setValue("markedAsViewHead", "Mark as viewed"); }}
                  >
                    Mark as viewed
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => { setValue("markedAsView", false); setValue("markedAsViewHead", "Mark as unviewed"); }}
                  >
                    Mark as unviewed
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <hr className=" border-secondary-subtle" />
            <div className=" form-style">
              <div className="px-3">
                <FloatingLabel controlId="Notes" label="Notes">
                  <Form.Control
                    {...register("notes")}
                    as="textarea"
                    placeholder="Notes"
                    style={{ height: "100px" }}
                    value={watchVariable.notes ? watchVariable.notes : ""}
                  />
                </FloatingLabel>
                <div className="mt-3">
                  <Button
                    type="submit"
                    className="btn btn-brand-1 w-100"
                    id="save"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Form>
      </Offcanvas>
    </>
  );
};
export default MessageReview;






